import { DataTypeDefinition } from 'ag-grid-community/dist/lib/entities/dataType';

export function getCurrentLocale() {
  return typeof Xrm === 'undefined'
    ? 'de-CH'
    : (Xrm?.Utility.getGlobalContext().userSettings as any).formatInfoCultureName ??
        'de-CH';
}

export function getGridDataTypeDefinitions<T>() {
  return {
    // override `date` to handle custom date format `dd/mm/yyyy`
    date: {
      baseDataType: 'date',
      extendsDataType: 'date',
      valueParser: (params) => {
        if (params === null || params.newValue == null) {
          return null;
        }
        // convert from `dd/mm/yyyy`
        const dateParts = params.newValue.split('/');
        return dateParts.length === 3
          ? new Date(
              parseInt(dateParts[2]),
              parseInt(dateParts[1]) - 1,
              parseInt(dateParts[0]),
            )
          : null;
      },
      valueFormatter: (params) => {
        // convert to `dd/mm/yyyy`
        return params.value == null
          ? ''
          : `${params.value.getDate()}/${params.value.getMonth() + 1}/${params.value.getFullYear}`;
      },
    } as DataTypeDefinition<T>,
  };
}
